<template>
    <component :is="currentComponent" :userName="userName" :isLogged="isLogged" :subscriptionHide="subscriptionHide"
               :subscription="Boolean(subscription)" @update-subscription="handleUpdateSubscription"></component>
    <router-view></router-view>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import AdminNavigation from "./components/AdminNavigation.vue";
import {loggedUser} from "@/plugins/common/user";

export default {
    name: 'App',
    components: {
        Navigation,
        AdminNavigation
    },
    data() {
        return {
            userName: '',
            isLogged: false,
            subscriptionHide: false,
            subscription: false
        };
    },
    computed: {
        currentComponent() {
            return this.$route.meta.showAdminNavigation ? 'AdminNavigation' : 'Navigation';
        }
    },
    watch: {
        '$route'() {
            this.isUserLogged();
        }
    },
    methods: {
        isUserLogged() {
            const token = localStorage.getItem('token');
            if (token) {
                loggedUser().then(response => {
                    if (response.success) {
                        this.isLogged = true;
                        this.userName = response.user.email;
                        this.subscriptionHide = response.subscription?.type === 'one-time';
                        this.subscription = response.user.subscription || false;
                    } else {
                        this.resetUserState();
                    }
                }).catch(() => this.resetUserState());
            } else {
                this.resetUserState();
            }
        },
        resetUserState() {
            this.isLogged = false;
            this.userName = '';
            this.subscription = false;
        },
        handleUpdateSubscription(newSubscription) {
            this.subscription = newSubscription;
            this.subscriptionHide = newSubscription;
        }
    }
}
</script>
