import ajax from "@/plugins/api";

function processSubscription(paymentID, paymentType, email, password, regType, telegramData, gmailData) {
    return ajax.post('/subscription', {
        'paymentMethodID': paymentID,
        'email': email,
        'password': password,
        'paymentType': paymentType,
        'regType': regType,
        'telegramData': telegramData,
        'authToken': gmailData
    }).then(response => {
        if (response.data.success) {
            setLoginData(response.data);
        }
        return response.data;
    }).catch(() => {
            return false;
        }
    )
}

function processSubscriptionWithoutReg(paymentID, paymentType) {
    return ajax.post('/subscription-without-reg', {
        'paymentMethodID': paymentID,
        'paymentType': paymentType,
    }).then(response => {
        return response.data;
    }).catch(() => {
            return false;
        }
    )
}

function cancelSubscription() {
    return ajax.post('/cancel-subscription').then(response => {
        return response.data;
    }).catch(() => {
            return false;
        }
    )
}

function sendMessage(name, email, message) {
    return ajax.post('/send-message', {
        name: name,
        email: email,
        message: message
    }).then(response => {
        return !!response.data.success;
    }).catch(() => {
            return false;
        }
    )
}

function getSettings() {
    return ajax.get('/settings').then(response => {
        return response.data;
    }).catch(() => {
            return false;
        }
    )
}

function updateSettings(contactEmail, monthlySub, oneTimeSub) {
    return ajax.post('/update-settings', {
        'contactEmail': contactEmail,
        'monthlySub': monthlySub,
        'oneTimeSub': oneTimeSub
    }).then(response => {
        return response.data;
    }).catch(() => {
            return false;
        }
    )
}

function setLoginData(data) {
    const item = localStorage.getItem('token');
    if (item !== null) {
        localStorage.removeItem('token');
    }
    const user = localStorage.getItem('user');
    if (user !== null) {
        localStorage.removeItem('user');
    }
    localStorage.setItem('user', data.user.email);
    localStorage.setItem('token', data.token);
}

export {
    processSubscription,
    processSubscriptionWithoutReg,
    sendMessage,
    getSettings,
    cancelSubscription,
    updateSettings
}