import {createRouter, createWebHistory} from 'vue-router';
import {isAdmin} from "@/plugins/common/user";

const routes = [
    {
        path: '/',
        redirect: '/collections',
        meta: {
            title: 'Collections',
        },
    },
    {
        path: '/collections',
        name: 'Collections',
        component: () => import('../views/Collections.vue'),
        meta: {
            title: 'Collections',
            showNavigation: true,
        },
        //meta: { requiresAuth: true }
    },
    {
        path: '/goddesses',
        name: 'Goddesses',
        component: () => import('../views/Goddesses.vue'),
        meta: {
            title: 'Goddesses',
            showNavigation: true,
        },
    },
    {
        path: '/goddesses/:goddess_id',
        name: 'Goddess collections',
        component: () => import('../views/GoddessCollections.vue'),
        meta: {
            title: 'Goddesses'
        }
    },
    {
        path: '/goddesses/:goddess_id/:collection_id/:paid',
        name: 'Goddess photos',
        component: () => import('../views/Photos.vue'),
        meta: {
            title: 'Goddesses'
        }
    },
    {
        path: '/collections/:collection_id/:paid',
        name: 'Collection photos',
        component: () => import('../views/Photos.vue'),
        meta: {
            title: 'Collections'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        meta: {
            title: 'Login'
        }
    },
    {
        path: '/signup',
        name: 'Sign up',
        component: () => import('../views/Registration.vue'),
        meta: {
            title: 'Sign up'
        }
    },
    {
        path: '/forgotPassword',
        name: 'Password recovery',
        component: () => import('../views/ForgotPassword.vue'),
        meta: {
            title: 'Forgot password'
        }
    },
    {
        path: '/resetPassword',
        name: 'Reset password',
        component: () => import('../views/ResetPassword.vue'),
        meta: {
            title: 'Reset password'
        }
    },
    {
        path: '/contactme',
        name: 'Contact me',
        component: () => import('../views/Contact.vue'),
        meta: {
            title: 'Contact me'
        }
    },
    {
        path: '/admin/goddesses',
        name: 'Goddesses admin',
        component: () => import('../views/admin/Goddesses.vue'),
        meta: {
            title: 'Goddesses',
            showAdminNavigation: true,
            requiresAdmin: true
        },
    },
    {
        path: '/admin/collections/:goddess_id',
        name: 'Collections admin',
        component: () => import('../views/admin/Collections.vue'),
        meta: {
            title: 'Collections',
            showAdminNavigation: true,
            requiresAdmin: true
        },
    },
    {
        path: '/admin/photos/:collection_id',
        name: 'Photos admin',
        component: () => import('../views/admin/Photos.vue'),
        meta: {
            title: 'Photos',
            showAdminNavigation: true,
            requiresAdmin: true
        },
    },
    {
        path: '/admin/users',
        name: 'Users admin',
        component: () => import('../views/admin/Users.vue'),
        meta: {
            title: 'Users',
            showAdminNavigation: true,
            requiresAdmin: true
        },
    },
    {
        path: '/admin/settings',
        name: 'Settings admin',
        component: () => import('../views/admin/Settings.vue'),
        meta: {
            title: 'Settings',
            showAdminNavigation: true,
            requiresAdmin: true
        },
    },
    {
        path: '/payment',
        name: 'Payment',
        component: () => import('../views/Payment.vue'),
        mety: {
            title: 'Payment'
        }
    },
    {
        path: '/subscription',
        name: 'Subscription',
        component: () => import('../views/Subscription.vue'),
        meta: {
            title: 'Subscription'
        }
    },
    {
        path: '/search',
        name: 'Search',
        component: () => import('../views/Search.vue'),
        meta: {
            title: 'Search'
        }
    },
    {
        path: '/notfound',
        name: '404',
        component: () => import('../views/NotFound.vue'),
        meta: {
            title: '404'
        }
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/notfound',
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    const defaultTitle = '';
    document.title = to.meta.title || defaultTitle;
    if (to.meta.requiresAdmin) {
        isAdmin().then(response => {
                if (!response) {
                    localStorage.setItem('ad','false');
                    return next('/collections');
                } else {
                    localStorage.setItem('ad','true');
                    return next();
                }
            }).catch(() => {
                localStorage.setItem('ad','false');
                return next('/collections');
            });
    } else {
        if(localStorage.getItem('ad') !== null && localStorage.getItem('ad') === 'true'){
            return next('/admin/goddesses');
        }
        return next();
    }
});
export default router
