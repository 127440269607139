import { createApp, ref } from 'vue';
import App from './App.vue';
import router from "@/router";
import './assets/css/main.css';

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

const app = createApp(App);
app.config.globalProperties.$storageUrl = 'https://mkluka.com/storage/';
//app.config.globalProperties.$storageUrl = 'http://portfolio.local/storage/';
app.config.globalProperties.isLoggedIn = ref(false);
app.config.globalProperties.hasSubscription = ref(false);

app.directive('disable-right-click', {
    beforeMount(el) {
      el.addEventListener('contextmenu', function (e) {
        e.preventDefault();
        return false;
      });
    }
});
app.use(VueViewer);
app.use(router).mount('#app');

export default app;
