<template>
    <div class="modal" v-if="visible">
        <div class="modal-content animate__animated animate__zoomIn animate__faster">
            <span class="close" @click="close"><i class="bi bi-x-lg"></i></span>
            <h2>Change password</h2>

            <form @submit.prevent="changePassword" class="modal-form">
                
                <label for="password">Old password *</label>
                <div class="password-wrapper">
                    <input
                        id="password"
                        :type="isOldPasswordVisible ? 'text' : 'password'"
                        name="password"
                        placeholder="Old password ..."
                        v-model="oldPassword"
                    />
                    <i
                        class="bi"
                        :class="isOldPasswordVisible ? 'bi-eye' : 'bi-eye-slash'"
                        @click="isOldPasswordVisible = !isOldPasswordVisible"
                    ></i>
                </div>

                <label for="password">New password *</label>
                <div class="password-wrapper">
                    <input
                        id="password"
                        :type="isNewPasswordVisible ? 'text' : 'password'"
                        name="password"
                        placeholder="New password ..."
                        v-model="newPassword"
                    />
                    <i
                        class="bi"
                        :class="isNewPasswordVisible ? 'bi-eye' : 'bi-eye-slash'"
                        @click="isNewPasswordVisible = !isNewPasswordVisible"
                    ></i>
                </div>
                <button v-if="loading" class="button loading" disabled><clip-loader :loading="loading" color="#FFFFFF" size="20px"></clip-loader></button>
                <button v-else type="submit" class="button">Save</button>
            </form>

        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import {changePassword} from "@/plugins/common/user";
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

export default {
    props: {
        visible: Boolean
    },
    components: {
        ClipLoader
    },
    data() {
        return {
            isNewPasswordVisible: false,
            isOldPasswordVisible: false,
            oldPassword: '',
            newPassword: '',
            loading: false,
            toast: Swal.mixin({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                background: '#1E1E1E',
                color: '#FFFFFF',
                showClass: {
                    popup: `
                    animate__animated
                    animate__slideInRight
                    animate__faster
                    `
                },
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            }),
        }
    },
    methods: {
        close() {
            this.$emit('update:visible', false);
        },
        changePassword() {
            this.loading = true;
            changePassword(this.oldPassword,this.newPassword).then(response => {
                if(response.success){
                    this.oldPassword = '';
                    this.newPassword = '';
                    this.toast.fire({
                        icon: "success",
                        title: "Password updated successfully!"
                    });
                    this.loading = false;
                    this.close();
                }else{
                    this.loading = false;
                    console.log(response.errorMsg)
                }
            }).catch(e => {
                this.loading = false;
                console.log(e)
            });
            console.log(this.newPassword);
        }
    }
};
</script>

<style scoped>
.button {
    margin-top: 10px;
}
</style>
