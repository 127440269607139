import axios from 'axios';

const ajax = axios.create({
    baseURL: '/api',
    //baseURL: 'http://portfolio.local/api',
    headers: {
        "content-type": "application/json"
    },
});

ajax.CancelToken = axios.CancelToken;
ajax.isCancel = axios.isCancel;

ajax.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
);


ajax.interceptors.response.use(
    (response) => {
        if (response.data.code !== undefined && response.data.code === "401") {
            console.log('test123');
        }
        return response;
    },
    (error) => {
        // The request was made and the server responded with a status code
        return Promise.reject(error);
    }
);

export default ajax